import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tooltip", "tooltipText"]
  static classes = ["visibility", "hidden"]
  static values = { code: String, successMessage: String, errorMessage: String }

  connect() {
    // hide tooltip initially
    this.tooltipTarget.classList.add(...this.hiddenClasses)
  }

  copy() {
    // copy code to clipboard

    navigator.clipboard
      .writeText(this.codeValue)
      .then(() => {
        this.tooltipTextTarget.innerHTML = this.successMessageValue
      })
      .catch(() => {
        this.tooltipTextTarget.innerHTML = this.errorMessageValue
      })
      .finally(() => {
        // show tooltip
        this.tooltipTarget.classList.remove(...this.hiddenClasses)
        this.tooltipTarget.classList.add(...this.visibilityClasses)

        // hide tooltip after 3 seconds
        window.setTimeout(() => {
          this.tooltipTarget.classList.remove(...this.visibilityClasses)
          this.tooltipTarget.classList.add(...this.hiddenClasses)
        }, 3000)
      })
  }
}
