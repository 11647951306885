import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="live-validator"
export default class extends Controller {
  static targets = ["input", "message"]
  static values = {
    url: String,
    field: String,
    error: Boolean,
  }

  static classes = ["error"]

  async validate(event) {
    const response = await post(this.urlValue, {
      body: { field: this.fieldValue, value: this.inputTarget.value },
      responseKind: "json",
    })
    if (response.ok) {
      this.errorValue = false
      this.messageTarget.innerHTML = ""
    } else {
      this.errorValue = true
      this.messageTarget.innerHTML = (await response.json).error
    }
  }

  errorValueChanged(hasError) {
    this.element.classList.toggle(this.errorClass, hasError)
  }
}
