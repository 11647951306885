import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="terms-validator"
export default class extends Controller {
  static targets = ["input", "message"]
  static values = {
    error: { type: Boolean, default: false },
  }

  static classes = ["error", "hidden"]

  async validate(event) {
    this.errorValue = !this.inputTarget.checked
  }

  errorValueChanged(hasError) {
    if (hasError) {
      this.inputTarget.classList.add(...this.errorClasses)
      this.messageTarget.classList.remove(...this.hiddenClasses)
    } else {
      this.inputTarget.classList.remove(...this.errorClasses)
      this.messageTarget.classList.add(...this.hiddenClasses)
    }
  }
}
