import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icon", "form"]

  toggle() {
    this.iconTarget.classList.toggle("-rotate-180")
    this.formTarget.classList.toggle("!flex")
  }
}
