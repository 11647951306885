import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["creditCardForm", "paymentCardToken", "paymentMethodError"]

  static classes = ["default", "active"]

  static values = {
    selectedPaymentMethodId: String,
    isCreditCard: Boolean,
    mollieProfileId: String,
    mollieTestMode: Boolean,
  }

  connect() {
    this.mollieCardComponent = null // NB: We must not initialize more than once and properly unmount
    this.form = this.element
  }

  disconnect() {
    if (this.mollieCardComponent) {
      this.mollieCardComponent.unmount()
    }
  }

  // action on pm radio button changed
  selectPaymentMethod(event) {
    this.selectedPaymentMethodIdValue = event.currentTarget.id
    this.isCreditCardValue = event.params.isCreditCard
  }

  // callback when payment method selected or on first load
  selectedPaymentMethodIdValueChanged(nowSelected, prevSelected) {
    if (nowSelected === "") return

    this.activate(nowSelected)
    this.deactivate(prevSelected)
    this.hasPaymentMethodErrorTarget &&
      this.paymentMethodErrorTarget.classList.add("hidden")
  }

  isCreditCardValueChanged() {
    this.creditCardFormTarget.hidden = !this.isCreditCardValue
    if (this.isCreditCardValue) this.mountMollieCreditCardForm()
  }

  activate(id) {
    const element = this.getElementById(id)
    if (!element) return
    element.classList.remove(...this.defaultClasses)
    element.classList.add(...this.activeClasses)
  }

  deactivate(id) {
    const element = this.getElementById(id)
    if (!element) return
    element.classList.add(...this.defaultClasses)
    element.classList.remove(...this.activeClasses)
  }

  getElementById(id) {
    if (id === "") return null
    return this.element.querySelector(`#${id}`)
  }

  get mollie() {
    return (this.mollieInstance ||= Mollie(this.mollieProfileIdValue, {
      // eslint-disable-line
      locale: "de_DE",
      testmode: this.mollieTestModeValue,
    }))
  }

  // NB: The component should not be mounted before the containing DOM element
  // is visible / rendered by the browser. Otherwise a11y is messed up.
  // See https://github.com/mollie/components-examples/issues/8
  mountMollieCreditCardForm() {
    // debugger
    if (this.mollieCardComponent) return

    this.mollieCardComponent = this.mollie.createComponent(
      "card",
      this.cardComponentOptions,
    )
    this.mollieCardComponent.mount("#credit-card-form-mollie")
  }

  async submitForm(event) {
    if (!this.isCreditCardValue) return

    event.preventDefault()
    const { token, error } = await this.mollie.createToken()

    if (error) {
      // NB: The official documentation does not describe what type of errors we can expect here.
      // We display a generic message to the customer and must rely on the Mollie Components API
      // displaying a helpful error message.
      alert("Etwas ist schiefgelaufen. Prüfe deine Kreditkartendaten bitte.")
      return
    }

    this.paymentCardTokenTarget.value = token
    this.form.requestSubmit()
  }

  get cardComponentOptions() {
    return {
      styles: {
        base: {
          color: getComputedStyle(document.body).getPropertyValue(
            "--color-gray-900",
          ),
          fontSize: "18px",
          fontWeight: "300",
        },
      },
      components: {
        cardHolder: { label: "Name auf der Kreditkarte *" },
        cardNumber: { label: "Kreditkartennummer *" },
        expiryDate: { label: "Ablaufdatum *" },
        verificationCode: { label: "CVC/CVV *" },
      },
    }
  }
}
