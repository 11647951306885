import { Turbo } from "@hotwired/turbo-rails"

import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

import "~/stylesheets/payment.sass"

Turbo.session.drive = true

const application = Application.start()
const controllers = import.meta.glob("~/controllers/**/*_controller.js", {
  eager: true,
})
registerControllers(application, controllers)
